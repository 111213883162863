<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-card-text>
            <v-row>
              <v-col class="mt-5 ml-5 mr-5">
                <div v-html="form.header"></div>
                <div v-html="form.after_header"></div>
              </v-col>
            </v-row>
            <v-row v-if="form.id == 1">
              <v-col class="ml-5 mr-5">
                <table style="border-spacing: 0px; -webkit-border-spacing: 0px; border-collapse: collapse; -webkit-border-collapse:collapse;">
                  <!--TABLE HEADER-->
                  <thead>
                    <tr v-if="exam.exam_language === 'en'">
                      <th class="custom-th"/>
                      <th class="custom-th" style="background-color: #ff0000">F</th>
                      <th class="custom-th" style="background-color: #ffc000">B</th>
                      <th class="custom-th" style="background-color: #c6e0b4">N</th>
                      <th class="custom-th" style="background-color: #92d050">G</th>
                      <th class="custom-th">D.N.A.</th>
                    </tr>
                    <tr v-else>
                      <th class="custom-th"/>
                      <th class="custom-th" style="background-color: #ff0000">O</th>
                      <th class="custom-th" style="background-color: #ffc000">S</th>
                      <th class="custom-th" style="background-color: #c6e0b4">N</th>
                      <th class="custom-th" style="background-color: #92d050">G</th>
                      <th class="custom-th">N.V.T.</th>
                    </tr>
                  </thead>
                  <!--TABLE BODY-->
                  <tbody>
                    <!--LOOP THROUGH CATEGORIES-->
                    <template v-for="(category, category_index) in form.categories">
                      <tr :key="category_index">
                        <td class="custom-td" style="font-weight: bold;" colspan="6">{{ category + ':' }}</td>
                      </tr>
                      <!--LOOP THROUGH QUESTIONS-->
                      <template v-for="(question, question_index) in form.questions">
                        <tr v-if="question.category.name === category" :key="category_index + '_' + question_index">
                          <td class="custom-td">{{ question.name }}</td>
                          <td class="custom-td"><v-select v-model="answers[question_index]" :items="[1,2,3]" @change="answerChanged(question_index)"/></td>
                          <td class="custom-td"><v-select v-model="answers[question_index]" :items="[4,5]" @change="answerChanged(question_index)"/></td>
                          <td class="custom-td"><v-select v-model="answers[question_index]" :items="[6,7]" @change="answerChanged(question_index)"/></td>
                          <td class="custom-td"><v-select v-model="answers[question_index]" :items="[8,9,10]" @change="answerChanged(question_index)"/></td>
                          <td class="custom-td" style="text-align: center;"><v-checkbox v-model="d_n_a[question_index]" @change="dnaChanged(question_index)"/></td>
                        </tr>
                      </template>
                    </template>
                    <!--TABLE FOOTER-->
                    <tr>
                      <td class="custom-td"/>
                      <td class="custom-td" style="font-weight: bold; text-align: center;">{{ $store.getters.translate("1_tm_3") }}</td>
                      <td class="custom-td" style="font-weight: bold; text-align: center;">{{ $store.getters.translate("4_tm_5") }}</td>
                      <td class="custom-td" style="font-weight: bold; text-align: center;">{{ $store.getters.translate("6_tm_7") }}</td>
                      <td class="custom-td" style="font-weight: bold; text-align: center;">{{ $store.getters.translate("8_tm_10") }}</td>
                      <td class="custom-td"/>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col class="ml-5 mr-5">
                <template v-for="(category, category_index) in form.categories">
                  <!--LOOP THROUGH QUESTIONS-->
                  <template v-for="(question, question_index) in form.questions">
                    <template v-if="question.category.name === category">
                      <p :key="category_index + '_' + question_index">{{ question.name }}</p>
                      <v-text-field
                          v-if="question.type === 'text_field'"
                          :key="category_index + '_' + question_index"
                          v-model="answers[question_index]"/>
                      <v-textarea
                          v-else-if="question.type === 'text_area'"
                          :key="category_index + '_' + question_index"
                          outlined
                          v-model="answers[question_index]"/>
                      <v-switch
                          v-else-if="question.type === 'boolean'"
                          v-model="answers[question_index]"
                          :key="category_index + '_' + question_index"
                          dense/>
                      <template v-else-if="question.type === 'select_single'">
                        <template v-for="(available_answer, available_answer_index) in question.available_answers">
                          <template v-if="question.answers">
                            <template v-for="(answer, answer_index) in question.answers">
                              <template v-if="answer.name === available_answer && answer.image">
                                <img :key="category_index + '_' + question_index + '_' + available_answer_index + '_' + answer_index" :src="answer.image.full_path" @click="imageClick(question_index, answer.name)" style="margin-left: 15px;" :style="answers[question_index] === answer.name ? 'width: 175px' : 'width: 150px'"/>
                              </template>
                            </template>
                          </template>
                          <template v-else>
<!--                          SHOULD ADD RADIOBOXES HERE-->
                          </template>
                        </template>
                      </template>
                      <template v-else-if="question.type === 'select_multiple'">
<!--                          SHOULD ADD CHECKBOXES HERE-->
                      </template>
                      <v-text-field
                          v-else-if="question.type === 'date'"
                          v-model="answers[question_index]"
                          :key="category_index + '_' + question_index"
                          v-mask="'##-##-####'"
                          placeholder="dd-mm-yyyy"/>
                    </template>
                  </template>
                </template>
              </v-col>
            </v-row>
            <template v-if="form.before_footer.includes('${personal-remarks}')">
              <v-row>
                <v-col class="ml-5 mr-5" v-html="form.before_footer.split('${personal-remarks}')[0]"/>
              </v-row>
              <v-row>
                <v-col class="pb-0 pt-0 ml-5 mr-5">
                  <v-textarea outlined v-model="personal_remarks"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 ml-5 mr-5" v-html="form.before_footer.split('${personal-remarks}')[1]"/>
              </v-row>
            </template>
            <template v-else>
              <v-row>
                <v-col class="ml-5 mr-5">
                  <div v-html="form.before_footer"></div>
                </v-col>
              </v-row>
            </template>
            <!--not sure if the footer is required-->
            <!--<v-row>
              <v-col class="ml-5 mr-5">
                <div v-html="form.footer"></div>
              </v-col>
            </v-row>-->
            <v-row>
              <v-col class="mb-5 ml-5 mr-5">
                <v-btn @click="save" :disabled="loading" :loading="loading" large block tile color="primary">
                  {{ $store.getters.translate("save") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["candidate", "exam", "template_id"],
  data() {
    return {
      loading: false,
      personal_remarks: '',
      form: {
        header: null,
        footer: null
      },
      answers: [],
      d_n_a: [],
    }
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/portal/get-form", { exam_id: this.exam.id, candidate_id: this.candidate.id, template_id: this.template_id, language: this.exam.exam_language })
          .then((response) => {
            this.loading = false;
            this.form = response.data;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    save() {
      if(!this.loading) {
        let answers_set = true;
        //CONVERT DNA CHECKBOXES TO ANSWERS = 0
        for (let i = 0; i < this.form.questions.length; i++) {
          if (this.d_n_a[i]) {
            this.answers[i] = 0;
          }
        }
        for (let i = 0; i < this.form.questions.length; i++) {
          if (this.answers[i] === undefined || this.answers[i] === null) {
            answers_set = false;
            break;
          }
        }
        if (answers_set) {
          this.loading = true;
          this.$http.post(this.$store.getters.appUrl + "v2/portal/examination-form", {
                exam_id: this.exam.id,
                candidate_id: this.candidate.id,
                form_id: this.form.id,
                template_id: this.template_id,
                language: this.exam.exam_language,
                questions: this.form.questions,
                answers: this.answers,
                html: this.generateHtml(),
                personal_remarks: this.personal_remarks,
              })
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("success"));
                  this.$emit('form_filled');
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                this.$toasted.error(error.response.data.message);
                this.loading = false;
              });
        } else {
          this.$toasted.error(this.$store.getters.translate("please_fill_all_required_fields"));
        }
      }
    },
    answerChanged(index) {
      if(this.answers[index] !== 0) {
        this.d_n_a[index] = false;
      }
    },
    dnaChanged(index) {
      if(this.d_n_a[index]) {
        this.answers[index] = 0;
      }
    },
    generateHtml() {
      let html = this.form.after_header;
      if(this.form.id === 1) {
        html += this.answersTable();
      }
      else {
        //TABLE BODY, LOOP THROUGH CATEGORIES
        Object.values(this.form.categories).forEach((category) => {
          //LOOP THROUGH QUESTIONS
          for (let x = 0; x < this.form.questions.length; x++) {
            if(this.form.questions[x].category.name === category) {
              html += '<p>' + this.form.questions[x].name + '</p>';
              if (this.form.questions[x].type === "text_field" || this.form.questions[x].type === "text_area" || this.form.questions[x].type === "date") {
                html += '<p>' + this.answers[x] + '</p>';
              }
              else if (this.form.questions[x].type === "boolean") {
                if(this.answers[x]) {
                  html += "<p><svg data-layer='53fdbc09-5421-4390-a26f-dd60a2515111' preserveAspectRatio='none' viewBox='2.999999523162842 2.999999523162842 25.999998092651367 26' class='icon-ready' width='18' height='18'><path fill='#008000' d='M 26.11111068725586 2.999999523162842 L 5.888888835906982 2.999999523162842 C 4.285554885864258 2.999999523162842 2.999999523162842 4.299999713897705 2.999999523162842 5.888888835906982 L 2.999999523162842 26.11111068725586 C 2.999999523162842 27.69999885559082 4.285554885864258 28.99999809265137 5.888888835906982 28.99999809265137 L 26.11111068725586 28.99999809265137 C 27.71444320678711 28.99999809265137 28.99999809265137 27.69999885559082 28.99999809265137 26.11111068725586 L 28.99999809265137 5.888888835906982 C 28.99999809265137 4.299999713897705 27.71444320678711 2.999999523162842 26.11111068725586 2.999999523162842 Z M 13.11111164093018 23.22222137451172 L 5.888888835906982 15.99999904632568 L 7.925554752349854 13.96333312988281 L 13.11111164093018 19.13444328308105 L 24.07444381713867 8.171111106872559 L 26.11111068725586 10.22222232818604 L 13.11111164093018 23.22222137451172 Z'></path></svg></p>";
                }
                else {
                  html +=  "<p><svg data-layer='6e603f22-b4e4-4b9f-a319-a90a916d27dc' preserveAspectRatio='none' viewBox='2.999999523162842 2.999999523162842 25.999998092651367 26' class='icon-non'><path fill='#cc0000' d='M 26.11111068725586 2.999999523162842 L 5.888888835906982 2.999999523162842 C 4.299999713897705 2.999999523162842 2.999999523162842 4.299999713897705 2.999999523162842 5.888888835906982 L 2.999999523162842 26.11111068725586 C 2.999999523162842 27.69999885559082 4.299999713897705 28.99999809265137 5.888888835906982 28.99999809265137 L 26.11111068725586 28.99999809265137 C 27.69999885559082 28.99999809265137 28.99999809265137 27.69999885559082 28.99999809265137 26.11111068725586 L 28.99999809265137 5.888888835906982 C 28.99999809265137 4.299999713897705 27.69999885559082 2.999999523162842 26.11111068725586 2.999999523162842 Z M 23.22222137451172 17.44444274902344 L 8.777777671813965 17.44444274902344 L 8.777777671813965 14.55555534362793 L 23.22222137451172 14.55555534362793 L 23.22222137451172 17.44444274902344 Z'></path></svg></p>";
                }
              }
              else if (this.form.questions[x].type === "select_single") {
                html += '<p>';
                this.form.questions[x].available_answers.forEach((available_answer) => {
                  if(this.form.questions[x].answers) {
                    this.form.questions[x].answers.forEach((answer) => {
                      if(answer.name === available_answer && answer.image) {
                        let width = 400/this.form.questions[x].available_answers.length;
                        if(this.answers[x] === answer.name) {
                          width += 25;
                        }
                        html += '<img src="' + answer.image.full_path + '" style="width: ' + width + 'px; margin-left: 15px;"/>';
                      }
                    });
                  }
                  else {
                    //SHOULD ADD RADIOBOXES HERE
                  }
                });
                html += '</p>';
              }
              else if (this.form.questions[x].type === "select_multiple") {
                //SHOULD ADD CHECKBOXES HERE
              }
            }
          }
        });
      }
      if(this.form.before_footer.includes('${personal-remarks}')) {
        html += this.form.before_footer.replace('${personal-remarks}', this.personal_remarks);
      }
      else {
        html += this.form.before_footer;
      }
      return html;
    },
    answersTable() {
      let html = '<table style="border-spacing: 0px; -webkit-border-spacing: 0px; border-collapse: collapse; -webkit-border-collapse: collapse; page-break-inside: avoid;">';
      //TABLE HEADER
        html += '<thead>';
          html += '<tr>';
          html += '<th></th>';
            if(this.exam.exam_language === 'en') {
              html += '<th style="text-align: center; background-color: #ff0000;">F</th>';
              html += '<th style="text-align: center; background-color: #ffc000;">B</th>';
              html += '<th style="text-align: center; background-color: #c6e0b4;">N</th>';
              html += '<th style="text-align: center; background-color: #92d050;">G</th>';
              html += '<th style="text-align: center;">D.N.A.</th>';
            }
            else {
              html += '<th style="text-align: center; background-color: #ff0000;">O</th>';
              html += '<th style="text-align: center; background-color: #ffc000;">S</th>';
              html += '<th style="text-align: center; background-color: #c6e0b4;">N</th>';
              html += '<th style="text-align: center; background-color: #92d050;">G</th>';
              html += '<th style="text-align: center;">N.V.T.</th>';
            }
          html += '</tr>';
        html += '</thead>';
        html += '<tbody>';
      //TABLE BODY, LOOP THROUGH CATEGORIES
      Object.values(this.form.categories).forEach((category) => {
        html += '<tr><td style="font-weight: bold;" colspan="6">' + category + ':' + '</td></tr>';
        //LOOP THROUGH QUESTIONS
        for (let x = 0; x < this.form.questions.length; x++) {
          if(this.form.questions[x].category.name === category) {
            html += '<tr>';
            html += '<td>' + this.form.questions[x].name + '</td>';
            if (this.answers[x] === 1 || this.answers[x] === 2 || this.answers[x] === 3) {
              html += '<td style="text-align: center; background-color: #ff0000;">' + this.answers[x] + '</td><td></td><td></td><td></td>';
            } else if (this.answers[x] === 4 || this.answers[x] === 5) {
              html += '<td></td><td style="text-align: center; background-color: #ffc000;">' + this.answers[x] + '</td><td></td><td></td>';
            } else if (this.answers[x] === 6 || this.answers[x] === 7) {
              html += '<td></td><td></td><td style="text-align: center; background-color: #c6e0b4;">' + this.answers[x] + '</td><td></td>';
            } else if (this.answers[x] === 8 || this.answers[x] === 9 || this.answers[x] === 10) {
              html += '<td></td><td></td><td></td><td style="text-align: center; background-color: #92d050;">' + this.answers[x] + '</td>';
            } else {
              html += '<td></td><td></td><td></td><td></td>';
            }
            if (this.d_n_a[x]) {
              html += "<td style='text-align: center;'><svg data-layer='53fdbc09-5421-4390-a26f-dd60a2515111' preserveAspectRatio='none' viewBox='2.999999523162842 2.999999523162842 25.999998092651367 26' class='icon-ready' width='18' height='18'><path fill='#008000' d='M 26.11111068725586 2.999999523162842 L 5.888888835906982 2.999999523162842 C 4.285554885864258 2.999999523162842 2.999999523162842 4.299999713897705 2.999999523162842 5.888888835906982 L 2.999999523162842 26.11111068725586 C 2.999999523162842 27.69999885559082 4.285554885864258 28.99999809265137 5.888888835906982 28.99999809265137 L 26.11111068725586 28.99999809265137 C 27.71444320678711 28.99999809265137 28.99999809265137 27.69999885559082 28.99999809265137 26.11111068725586 L 28.99999809265137 5.888888835906982 C 28.99999809265137 4.299999713897705 27.71444320678711 2.999999523162842 26.11111068725586 2.999999523162842 Z M 13.11111164093018 23.22222137451172 L 5.888888835906982 15.99999904632568 L 7.925554752349854 13.96333312988281 L 13.11111164093018 19.13444328308105 L 24.07444381713867 8.171111106872559 L 26.11111068725586 10.22222232818604 L 13.11111164093018 23.22222137451172 Z'></path></svg></td>";
            } else {
              html += '<td></td>';
            }
            html += '</tr>';
          }
        }
      });
      //TABLE FOOTER
          html += '<tr>';
            html += '<td></td>';
            html += '<td style="font-weight: bold; text-align: center;">' + this.$store.getters.translate("1_tm_3") + '</td>';
            html += '<td style="font-weight: bold; text-align: center;">' + this.$store.getters.translate("4_tm_5") + '</td>';
            html += '<td style="font-weight: bold; text-align: center;">' + this.$store.getters.translate("6_tm_7") + '</td>';
            html += '<td style="font-weight: bold; text-align: center;">' + this.$store.getters.translate("8_tm_10") + '</td>';
            html += '<td></td>';
          html += '</tr>';
        html += '</tbody>';
      html += '</table>';
      return html;
    },
    imageClick(question_index, answer) {
      this.answers[question_index] = answer;
      this.$forceUpdate();
    },
  },
}
</script>

<style>
.custom-th {
  border:1px solid grey; padding: 10px;
}
.custom-td {
  border:1px solid grey; padding: 2px 10px; height: 24px; vertical-align: middle;
}
</style>